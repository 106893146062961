/* eslint "sort-keys": ["warn", "asc", {"caseSensitive": false, "natural": false, "minKeys": 2}] */
export default {
  CartButtons: {
    addToCart: 'Add to Cart',
    publishNow: 'Publish Now',
  },
  GuideFeedbackModal: {
    bodyMessage:
      'Please be as specific as possible. Thank you for your feedback!',
    modalHeader: 'Why are you going to ControlPoint Classic?',
    sendButton: 'Send feedback and go to Classic',
    skipButton: 'Skip and go to Classic',
    successMessage: 'Going to Classic...',
    successMessageBold: 'Thank you! ',
  },
  Help: {
    callLabel: 'or call',
    createTicketButton: 'Create a ticket',
    helpHeader: 'Help',
    helpOnlineLabel: 'HELP Online',
    phoneLabel: '1-800-232-2677',
  },
  History: {
    addedToCartSubtitle: 'History of all changes added to the cart',
    changeText: 'Change:',
    pastChangesSubtitle:
      'History of all changes successfully published or sent to the store from the cart',
    searchLabel: 'Search',
    sectionLabel: 'Section',
    showMoreButtonLabel: 'Show more',
    title: 'History',
    updatedByText: 'Updated by:',
  },
  Home: {
    caresSupportTiersLabel: 'CARES Support Tiers',
    conceptTypeLIC: 'CONCEPT_TYPE_LIC',
    feedbackButtonLabel: 'Give feedback',
    header: 'ControlPoint',
  },
  MaintenanceMode: {
    bodyText: 'Automatically refreshing in {{counter}}',
    header: 'ControlPoint is currently down for maintenance',
    refreshButtonText: 'Refresh now',
    subText: 'We’re working on a fix and will be back online shortly.',
  },
  Reports: {
    discountsAndGiveawaysReport: {
      discountsAndGiveawaysLabel: 'Discounts and Giveaways',
    },
    generateLabel: 'Generate',
    header: 'Reports',
    localDiscountReport: {
      localDiscountLabel: 'Local Discount',
    },
    refundAndPromoFreeReport: {
      refundAndPromoFreeLabel: 'Refund and Promo Free',
    },
  },
  SelectStore: {
    next: 'Next',
    prev: 'Previous',
    selectStore: 'Find your store',
    selectStoreSearchInputPlaceholder: 'Search store name or number',
    storeRange:
      '{{startingStoreNumber}} - {{endingStoreNumber}} of {{totalStores}}',
  },
  SystemTools: {
    commandContactHostButton: 'Initiate download',
    commandContactHostError: 'Error downloading',
    commandContactHostLoading: 'Initiating...',
    commandContactHostPromptConfirm: 'Initiate download',
    commandContactHostPromptSubtitle:
      'Are you sure you want to initiate a ServicePoint EP download at the store?',
    commandContactHostPromptTitle: 'Contact Host',
    commandContactHostSubtitle:
      'Remotely triggers the store server to initiate a ServicePoint EP download',
    commandContactHostSuccess: 'Downloaded',
    commandContactHostTitle: 'Contact Host',

    commandGlobalDataButton: 'Fetch item data',
    commandGlobalDataError: 'Error fetching item data',
    commandGlobalDataLoading: 'Fetching...',
    commandGlobalDataSubtitle:
      'Fetches dynamic data (local items, item overrides, etc.) from the store',
    commandGlobalDataSuccess: 'Data received',
    commandGlobalDataTitle: 'Global Config',

    commandPollButton: 'Send status check',
    commandPollError: 'Error sending status check',
    commandPollLoading: 'Sending...',
    commandPollSubtitle: 'Performs a status check of the POS Agent Service',
    commandPollSuccess: 'Status check sent',
    commandPollTitle: 'Poll',

    commandPromptModalCancelButton: 'Cancel',
    commandPromptModalMessage: 'KPS and POS reboots are required',

    commandRelayGenButton: 'Re-generate and send',
    commandRelayGenError: 'Error regenerating/sending relay files',
    commandRelayGenLoading: 'Re-generating',
    commandRelayGenPromptConfirm: 'Re-generate and send',
    commandRelayGenPromptSubtitle:
      'Are you sure you want to generate and send ALL relay files to the store?',
    commandRelayGenPromptTitle: 'Relay Gen',
    commandRelayGenSubtitle:
      'Generates and sends ALL relay files to the store (requires a KPS and POS reboot)',
    commandRelayGenSuccess: 'Data sent',
    commandRelayGenTitle: 'Relay Gen',

    commandResendDataButton: 'Re-send data',
    commandResendDataError: 'Error resending data',
    commandResendDataLoading: 'Re-sending...',
    commandResendDataPromptConfirm: 'Re-send',
    commandResendDataPromptSubtitle:
      'Are you sure you want to re-send the latest data to the store?',
    commandResendDataPromptTitle: 'Re-send Data',
    commandResendDataSubtitle:
      'Re-sends ControlPoint data to store (requires a KPS and POS reboot)',
    commandResendDataSuccess: 'Data sent',
    commandResendDataTitle: 'Re-send Data',

    commandSyncReferenceDataButton: 'Fetch reference data',
    commandSyncReferenceDataError: 'Error fetching reference data',
    commandSyncReferenceDataLoading: 'Fetching...',
    commandSyncReferenceDataSubtitle:
      'Fetches static data (items, device groups, destinations, etc.) from the store',
    commandSyncReferenceDataSuccess: 'Reference data received',
    commandSyncReferenceDataTitle: 'Reference Data',

    devicesTablePage: {
      clearCacheLower: 'Clear cache',
      commandModalCancelButton: 'Cancel',
      commandModalHeader: 'Select Command',
      commandModalPublishButton: 'Publish',
      confirmClearCache: 'Yes, clear cache',
      noDevicesFound: 'No devices found',
      selectCommandButton: '+ Selected Command',
      tableHeaderAppVersion: 'App Version',
      tableHeaderDeviceType: 'Device Type',
      tableHeaderLastConnected: 'Last Connected to Big Peer',
      tableHeaderSerialNumber: 'Serial Number',
      tableSearchLabel: 'Search Devices',
      tableSearchPlaceholder: 'Enter device serial number',
    },

    generateTerminalNumbersSPFBButton: 'Generate',
    generateTerminalNumbersSPFBError:
      'Error generating terminal numbers for SPFB',
    generateTerminalNumbersSPFBLoading: 'Generating...',
    generateTerminalNumbersSPFBSubtitle:
      'Generates terminal numbers for SP Flex devices at the store',
    generateTerminalNumbersSPFBSuccess:
      'Terminal numbers generated for SP Flex',
    generateTerminalNumbersSPFBTitle: 'Generate SP Flex Terminal Numbers',

    generateTerminalNumbersVPButton: 'Generate',
    generateTerminalNumbersVPError: 'Error generating terminal numbers for VP',
    generateTerminalNumbersVPLoading: 'Generating...',
    generateTerminalNumbersVPSubtitle:
      'Generates terminal numbers for VP devices at the store',
    generateTerminalNumbersVPSuccess: 'Terminal numbers generated for VP',
    generateTerminalNumbersVPTitle: 'Generate VP Terminal Numbers',

    header: 'System Tools',
    headerCommercePointDevices: 'CommercePoint System Tools',
    headerServicePointDevices: 'ServicePoint System Tools',
    infoCardMessages: {
      cacheClearFailure:
        'Unable to clear cache due to a system error. Please try again.',
      cacheClearSuccess: 'Success, the cache has been cleared!',
      customItemNamesSuccess:
        'Success, custom item names data has been resent!',
      itemLocksSuccess: 'Success, item locks data has been resent!',
      menuDataFailure: 'Failure, menu data was not resent.',
      menuDataSuccess: 'Success, menu data has been resent!',
      playDataFailure:
        'Unable to resend play data due to a system error. Please try again.',
      playDataSuccess: 'Success, play data has been resent!',
      priceOverridesSuccess: 'Success, price overrides data has been resent!',
      userPinsSuccess: 'Success, user pins data has been resent!',
    },
    lastPolledStatus: 'Last Polled: {{date}}',
    modals: {
      allMenuBody:
        'Are you sure you want to resend all menu data for the venue, {venue}? This sends item locks, price overrides, and custom item names (short names) to this location.',
      allMenuHeader: 'Resend All Menu Data',
      cancel: 'Cancel',
      clearCacheBody:
        'Are you sure you want to clear cache for the device, {device}? You may want to clear cache for ViewPoint if a profile does not appear on the device after publishing or the device is lagging.',
      clearCacheUpper: 'Clear Cache',
      onlyCustomItemNamesBody:
        'Are you sure you want to resend custom item names (short names) data for the venue, {venue}? This sends all custom item names to this location.',
      onlyCustomItemNamesHeader: 'Resend Custom Item Names Data',
      onlyItemLocksBody:
        'Are you sure you want to resend item locks data for the venue, {venue}? This sends all item locks to this location.',
      onlyItemLocksHeader: 'Resend Item Locks Data',
      onlyPriceOverridesBody:
        'Are you sure you want to resend price overrides data for the venue, {venue}? This sends all price overrides to this location.',
      onlyPriceOverridesHeader: 'Resend Price Overrides Data',
      playBody:
        'Are you sure you want to resend the play data for the venue, {venue}? This sends all global and profile settings for SP Flex Blue and ViewPoint devices to this location.',
      playHeader: 'Resend Play Data',
      submit: 'Submit',
      userPinsBody:
        'Are you sure you want to resend the user pins data for the venue, {venue}? This sends all TMUA PIN login information to this location.',
      userPinsHeader: 'Resend User Pins Data',
      venueHeader: 'Select Venue',
      yesResend: 'Yes, Resend',
    },

    resendDataPage: {
      allMenu: 'Resend all Menu data',
      allMenuBody:
        'Send all item locks, price overrides, and custom item names (short names) data to the selected venue',
      change: 'Change',
      generateTerminalNumbersSPFButton: 'Generate',
      generateTerminalNumbersSPFError:
        'Error generating terminal numbers for SPF',
      generateTerminalNumbersSPFLoading: 'Generating...',
      generateTerminalNumbersSPFSubtitle:
        'Generates terminal numbers for SPF devices at the store',
      generateTerminalNumbersSPFSuccess: 'Terminal numbers generated for SPF',
      generateTerminalNumbersSPFTitle: 'Generate SPF Terminal Numbers',
      generateTerminalNumbersVPButton: 'Generate',
      generateTerminalNumbersVPError:
        'Error generating terminal numbers for VP',
      generateTerminalNumbersVPLoading: 'Generating...',
      generateTerminalNumbersVPSubtitle:
        'Generates terminal numbers for VP devices at the store',
      generateTerminalNumbersVPSuccess: 'Terminal numbers generated for VP',
      generateTerminalNumbersVPTitle: 'Generate VP Terminal Numbers',
      lastSentPlayData: 'Last sent on ',
      onlyCustomItemNames: 'Resend only Custom Item Names',
      onlyItemLocks: 'Resend only Item Locks data',
      onlyPriceOverrides: 'Resend only Price Overrides data',
      playData: 'Resend Play data',
      playDataBody:
        'Send all global and profile settings for SP Flex Blue and ViewPoint devices for the selected venue',
      resend: 'Resend',
      sectionTitleDevices: 'Devices',
      sectionTitleMenu: 'Menu',
      sectionTitlePlay: 'Play',
      userPins: 'Resend User Pins data',
      userPinsBody:
        'Send all TMUA PIN login information for the selected venue',
      venue: 'Venue: {{venue}}',
    },
    resendOverridesButton: 'Re-send',
    resendOverridesError: 'Error resending overrides',

    resendOverridesLoading: 'Re-sending...',

    resendOverridesSubtitle: 'Resends item override data to the store',

    resendOverridesSuccess: 'Data sent',

    resendOverridesTitle: 'Resend Overrides',

    resendPINOverridesButton: 'Resend PIN Overrides',
    resendPINOverridesError: 'Error resending PIN overrides',
    resendPINOverridesLoading: 'Re-sending...',
    resendPINOverridesSubtitle:
      'Resends pricing PIN Overrides from MenuDNA to the Store',
    resendPINOverridesSuccess: 'Data sent',
    resendPINOverridesTitle: 'Resend PIN Overrides',

    statusEmptyText: 'There are no statuses to show',
    statusTruncatedEllipsisText: '{{text}}...',
    statusTruncatedTextHideButton: 'Hide',
    statusTruncatedTextViewButton: 'View all',

    subheaderCommercePointDevices:
      'Manage the status and perform troubleshooting for SP Flex Blue and ViewPoint devices',
    subheaderServicePointDevices:
      'Manage the status and perform troubleshooting for SP Classic POS and KPS devices',
  },

  Venues: {
    activeVenuesLabel: 'Active Venues',
    addVenueButton: 'Add a Venue',
    archivedVenuesLabel: 'Archived Venues',
    createModalCancelButton: 'Cancel',
    createModalSubmitButton: 'Create Venue',
    detailsActivateButton: 'Activate this Venue',
    detailsArchiveButton: 'Archive this Venue',
    detailsDuplicateButton: 'Duplicate this Venue',
    detailsSaveButton: 'Save',
    emptyActiveVenuesLabel: 'No active venues',
    emptyArchivedVenuesLabel: 'No archived venues',
    idLabel: '({{storeNumber}}:{{venueId}})',
    popoverMenuActivateButton: 'Activate',
    popoverMenuArchiveButton: 'Archive',
    popoverMenuDeleteButton: 'Permanently Delete',
    popoverMenuDuplicateButton: 'Duplicate',
    popoverMenuEditButton: 'View/Edit Venue',
    popoverMenuResendButton: 'Resend Venue Data',
    promptModalCancelButton: 'Cancel',
    venueChangeButton: 'Change',
    venueChangeLabel: 'Venue: ',
    venueDetailActiveLabel: 'Status: Active',
    venueDetailArchivedLabel: 'Status: Archived',
    venueModalCancelButton: 'Cancel',
    venueModalHeader: 'Select a venue',
    venueModalSelectLabel: 'Venue',
    venueModalSubmitButton: 'Select',
  },
};
